body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
}

.no-scroll{
  overflow: hidden;
}

.ant-progress-bg{
  height: 9px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#root {
  height: 100%; /* remove this line to see div.app is no more 100% height */
}

.lds-ellipsis-big {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 16px;
  margin-left: 12px;
}
.lds-ellipsis-big div {
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #E6000B;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis-big div:nth-child(1) {
  left: 8px;

  background: #E6000B;
  animation: lds-ellipsis-big1 0.6s infinite;
}
.lds-ellipsis-big div:nth-child(2) {
  left: 8px;

  background: #007EA7;
  animation: lds-ellipsis-big2 0.6s infinite;
}
.lds-ellipsis-big div:nth-child(3) {
  left: 32px;
  background: #00A8E8;
  animation: lds-ellipsis-big2 0.6s infinite;
}
.lds-ellipsis-big div:nth-child(4) {
  left: 56px;
  background: #00A8E8;
  animation: lds-ellipsis-big3 0.6s infinite;
}
@keyframes lds-ellipsis-big1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis-big3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis-big2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 16px;
  margin-left: 12px;
}
.lds-ellipsis div {
  position: absolute;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #E6000B;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;

  background: #E6000B;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;

  background: #007EA7;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  background: #00A8E8;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  background: #00A8E8;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

button:focus {outline:0 !important;}  